<template>
  <v-app style="background-color:#f8f9fb" >


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilterDownload" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Em Manutenção
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilterDownload = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    <v-container style="background-color:#f8f9fb;" >
      <v-col cols="12">
        <filtro-component :opcoes="opcoes" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'mes'"
        @filtrar="filterData" :title_opcao="'Campanha'" :campos="'N'"
          ></filtro-component>
      </v-col>
    </v-container>


  <v-container style="background-color:#eee"  
    id="financeiro"
    fluid
    tag="section">
  
    <v-row>

      <v-col
      cols="3"
      sm="6"
      lg="4">
      
      <base-material-stats-card-contas
            elevation="1"
            color='blue'
            icon='mdi-chart-areaspline'
            :ico1="'mdi-chart-areaspline'"
            :titulo1="'Previsto'"
            :texto1="'Total de documentos (R$): '"
            :valor1="formatarMoeda(l_recebido_periodo_v2)"
            :texto2="'Total de documentos (Nº): '"
            :valor2="l_qtd_periodo_v2"
            :texto3="'Ticket Médio (R$): '"
            :valor3="formatarMoeda(l_media_periodo_v2)"
            :texto4="'Total de Contratos (Nº): '"
            :valor4="(l_contratos_periodo_v2)"
            :lista1="l_listagem"

          />
     
     </v-col>
     
      <v-col
      cols="3"
      sm="6"
      lg="4">
     
      <base-material-stats-card-contas
            elevation="1"
            color='success'
            icon='mdi-cash'
            :ico1="'mdi-cash'"
            :titulo1="'Recebido'"
            :texto1="'Total de documentos (R$): '"
            :valor1="formatarMoeda(recebido_periodo_v2)"
            :texto2="'Total de documentos (Nº): '"
            :valor2="qtd_periodo_v2"
            :texto3="'Ticket Médio (R$): '"
            :valor3="formatarMoeda(media_periodo_v2)"
            :texto4="'Total de Contratos (Nº): '"
            :valor4="(contratos_periodo_v2)"
            :lista1="listagem"

          />
     
     </v-col>

     

     <v-col
      cols="3"
      sm="6"
      lg="4">
     
      <base-material-stats-card-contas
            elevation="1"
            color='error'
            icon='mdi-alert-circle-outline'
            :ico1="'mdi-alert-circle-outline'"
            :titulo1="'Inadimplência'"
            :texto1="'Total de documentos (R$): '"
            :valor1="formatarMoeda(i_recebido_periodo_v2)"
            :texto2="'Total de documentos (Nº): '"
            :valor2="i_qtd_periodo_v2"
            :texto3="'Ticket Médio (R$): '"
            :valor3="formatarMoeda(i_media_periodo_v2)"
            :texto4="'Total de Contratos (Nº): '"
            :valor4="(i_contratos_periodo_v2)"
            :lista1="i_listagem"

          />
     
     </v-col>

     </v-row>


  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto
  import Contas from '../../services/contas' 
  import { ContentLoader } from 'vue-content-loader'

  export default {
    name: 'Contas',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,

    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())

      this.selectedStartDate2 = this.getFirstDayOfMonth(),
      this.selectedEndDate2 =  this.formatDateBR(new Date())
    },


    
    mounted() {
    // this.carregarLoad()
     //this.dashboard();
    },
   

    data () {
      return {
          produto: 'Todos',
          bool_01 : true,
          recebido_periodo_v2: 0,
          showFilter: false,
          showProgress: false,

          qtd_periodo_v2 : 0,   
          contratos_periodo_v2 : 0,    
          media_periodo_v2 : 0,
          listagem : [],

          l_recebido_periodo_v2: 0,
          l_qtd_periodo_v2 : 0,   
          l_contratos_periodo_v2 : 0,    
          l_media_periodo_v2 : 0,
          l_listagem : [],

          i_recebido_periodo_v2: 0,
          i_qtd_periodo_v2 : 0,   
          i_contratos_periodo_v2 : 0,    
          i_media_periodo_v2 : 0,
          i_listagem : [],

          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          opcoes: ["Todas","Campanha 50 Anos"],
          selectMode: "Vencimento",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          modos: ["Período"],
          campos: 'N',
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos e Cemiteriais"],
          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],

          
      
      }
    },

   
    methods: {
     

      filterData(valores) {
        
          this.carregarLoad(),
          this.isLoading = true;
          let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.selectMode = "Vencimento";
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.selectedStartDate2 = valores.selectedStartDate2;
          this.selectedEndDate2 = valores.selectedEndDate2;
          this.selectedStartDate3 = valores.selectedStartDate3;
          this.selectedEndDate3 = valores.selectedEndDate3;
          this.produto = valores.selectedProduto;
          this.dashboard();
      },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      carregarLoad(){
         this.bool_01 = true
      },

      async dashboard() {
        const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
        const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
        const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);


        if (diferencaEmDias > 366 || diferencaEmDias < 0) {
            this.isLoading = false;
            this.showFilter = true;
            this.showProgress = false;
            return;
        }

              Contas.total_recebimento_periodo_v3(formattedStartDate, formattedEndDate, this.selectedCompany,
                  this.selectedType, "Todos", this.selectedOpcao, this.produto
              )
              .then(response => {
                if (response.status === 200) {
                    const data = response.data; 

                    const totais = data.reduce(
                        (acc, item) => {
                            acc.total += item.total;
                            acc.qtd += item.qtd;
                            acc.contratos += item.contratos;
                            acc.mediaSoma += item.media;
                            acc.mediaCount += 1;
                            return acc;
                        },
                        { total: 0, qtd: 0, contratos: 0, mediaSoma: 0, mediaCount: 0 }
                    );

                    const mediaGeral = totais.mediaCount > 0 ? totais.mediaSoma / totais.mediaCount : 0;
                    this.recebido_periodo_v2 = totais.total;
                    this.qtd_periodo_v2 = totais.qtd;
                    this.contratos_periodo_v2 = totais.contratos;
                    this.media_periodo_v2 = mediaGeral;
                    this.listagem = data; 
                }

            })
            .catch(e => {
                console.error("Erro ao buscar os dados:", e);
            })
            .finally(() => {
                this.bool_02 = false;
            });

            Contas.total_lancamento_periodo_v3(formattedStartDate, formattedEndDate, this.selectedCompany,
            this.selectedType, "Todos", this.selectedOpcao, this.produto
            )
            .then(response => {
                if (response.status === 200) {
                    const data = response.data; 

                    const totais = data.reduce(
                        (acc, item) => {
                            acc.total += item.total;
                            acc.qtd += item.qtd;
                            acc.contratos += item.contratos;
                            acc.mediaSoma += item.media;
                            acc.mediaCount += 1;
                            return acc;
                        },
                        { total: 0, qtd: 0, contratos: 0, mediaSoma: 0, mediaCount: 0 }
                    );

                    const mediaGeral = totais.mediaCount > 0 ? totais.mediaSoma / totais.mediaCount : 0;
                    this.l_recebido_periodo_v2 = totais.total;
                    this.l_qtd_periodo_v2 = totais.qtd;
                    this.l_contratos_periodo_v2 = totais.contratos;
                    this.l_media_periodo_v2 = mediaGeral;
                    this.l_listagem = data; 
                }

            })
            .catch(e => {
                console.error("Erro ao buscar os dados:", e);
            })
            .finally(() => {
                this.bool_02 = false;
            });

            Contas.total_inadimplencia_periodo_v3(formattedStartDate, formattedEndDate, this.selectedCompany,
            this.selectedType, "Todos", this.selectedOpcao, this.produto
            )
            .then(response => {
                if (response.status === 200) {
                    const data = response.data; 

                    const totais = data.reduce(
                        (acc, item) => {
                            acc.total += item.total;
                            acc.qtd += item.qtd;
                            acc.contratos += item.contratos;
                            acc.mediaSoma += item.media;
                            acc.mediaCount += 1;
                            return acc;
                        },
                        { total: 0, qtd: 0, contratos: 0, mediaSoma: 0, mediaCount: 0 }
                    );

                    const mediaGeral = totais.mediaCount > 0 ? totais.mediaSoma / totais.mediaCount : 0;
                    this.i_recebido_periodo_v2 = totais.total;
                    this.i_qtd_periodo_v2 = totais.qtd;
                    this.i_contratos_periodo_v2 = totais.contratos;
                    this.i_media_periodo_v2 = mediaGeral;
                    this.i_listagem = data; 
                }

            })
            .catch(e => {
                console.error("Erro ao buscar os dados:", e);
            })
            .finally(() => {
                this.bool_02 = false;
            });
    },

  
    
      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

    calcularPercentual(valorParcial, valorTotal) {
      if (valorTotal === 0) {
        return 0; // Evitar divisão por zero
      }
      return ((valorParcial / valorTotal) * 100).toFixed(2);
    },
     


      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },
      }
  }


  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }



</script>
