import { http } from './config'
import authHeader from './auth-header';

export default {


    
    total_recebimento_periodo_v3: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
       
        return http.get('/contas-dash/total-recebimento-periodo-v3/', {
            params: params,
            headers: authHeader() 
        })
    },

    total_lancamento_periodo_v3: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
       
        return http.get('/contas-dash/total-lancamento-periodo-v3/', {
            params: params,
            headers: authHeader() 
        })
    },
    
    total_inadimplencia_periodo_v3: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
       
        return http.get('/contas-dash/total-inadimplencia-periodo-v3/', {
            params: params,
            headers: authHeader() 
        })
    },
    
   
    
}
